
  import { computed, defineComponent, ref, watch } from 'vue';
  import { Form, Field, ErrorMessage } from 'vee-validate';
  import { hideModal } from '@/core/helpers/dom';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useStore } from 'vuex';
  import * as Yup from 'yup';
  import { useI18n } from 'vue-i18n/index';
  import { FactorOptionObject } from '@/store/modules/IRE/FactorsModule';
  import Factors from '@/store/enums/IREFactorsEnums';
  let dynamicSchema = ref({});

  export default defineComponent({
    name: 'factor-values-editing',
    props: {
      editData: { type: Object, required: false },
      selectedAreaId: { type: Number, required: true },
    },
    components: {
      Form,
      ErrorMessage,
      Field,
    },
    emit: ['new-value-created'],
    async setup(props, { emit }) {
      const submitButtonRef = ref<null | HTMLButtonElement>(null);
      const editFactorValueModalRef = ref<null | HTMLElement>(null);
      const store = useStore();
      const { t, te } = useI18n();
      const factorTitle = ref<string>('');
      const optionsValues = ref<Array<FactorOptionObject>>([]);
      const areas = computed(() => store.getters.ireAreasAllList);
      const selectedArea = ref(areas.value[0]);

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      watch(
        () => props.editData,
        (data) => {
          optionsValues.value = data?.value.ireFactorsConfig || data?.value;
          dynamicSchema.value = {};
          if (!data?.value.ireFactorsConfig) {
            optionsValues.value.forEach((v) => {
              dynamicSchema.value[`field_${v.title}`] = Yup.string().required(
                () => translate('FACTOR_OPTION_IVE_IS_REQUIRED_FIELD')
              );
            });
          }

          if (data) {
            factorTitle.value = data.factorTitle;
          }
        }
      );
      watch(
        () => props.selectedAreaId,
        () => {
          selectedArea.value = areas.value.find((area) => {
            if (area.id === props.selectedAreaId) {
              return area;
            }
          });
        }
      );

      const validationSchema = computed(() => {
        return Yup.object(dynamicSchema.value);
      });
      const submit = async () => {
        if (submitButtonRef.value) {
          // eslint-disable-next-line
          submitButtonRef.value!.disabled = true;
          submitButtonRef.value.setAttribute('data-kt-indicator', 'on');
        }

        const factors = optionsValues.value.map((option) => {
          store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

          return {
            fOptionId: Number(option.fOptionId),
            value: option.value !== '' ? Number(option.value) : undefined,
            sizeId: props.editData?.value.sizeId || null,
            typeId: props.editData?.value.typeId || null,
            id: option.id,
          };
        });

        const payload = {
          areaId: props.selectedAreaId,
          ireFactorAreasValues: factors,
        };
        await store.dispatch(Actions.UPDATE_IRE_FACTOR_VALUES, payload);

        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButtonRef.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButtonRef.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_IRE_VALUE'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            emit('new-value-created');
            //Deactivate indicator
            submitButtonRef.value?.removeAttribute('data-kt-indicator');
            // eslint-disable-next-line
            submitButtonRef.value!.disabled = false;
            hideModal(editFactorValueModalRef.value);
          });
        }
      };

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        optionsValues,
        translate,
        submit,
        submitButtonRef,
        editFactorValueModalRef,
        factorTitle,
        selectedArea,
        Factors,
        validationSchema,
      };
    },
  });
